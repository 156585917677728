import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import React from 'react';
import '../styles/breadcrumbs.css';

export const Breadcrumbs = () => {
  const { pathname } = useLocation();
  const domain = 'https://axiomgeomatics.ca';

  const crumbs = pathname
    .replace(/-/g, ' ')
    .split('/')
    .slice(1)
    .filter((val) => val);

  const getUrl = (ndx) =>
    pathname
      .split('/')
      .slice(0, ndx + 2)
      .join('/');

  const breadcrumbSchema = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Home',
        item: domain + '/',
      },
      ...crumbs.map((crumb, index) => ({
        '@type': 'ListItem',
        position: index + 2,
        name: crumb,
        item: domain + getUrl(index),
      })),
    ],
  };

  return (
    <>
      <ul className="breadcrumb">
        <li className="breadcrumb-item" key="home">
          <Link to="/" className="active">
            Home
          </Link>
        </li>

        {crumbs.map((crumb, ndx) => (
          <li className="breadcrumb-item" key={crumb}>
            <Link to={getUrl(ndx)} className="active">
              {crumb}
            </Link>
          </li>
        ))}
      </ul>

      <script type="application/ld+json">{JSON.stringify(breadcrumbSchema)}</script>
    </>
  );
};
