import { Link } from 'gatsby';
import { useEffect, useState } from 'react';
import React from 'react';
import { useLocation } from '@reach/router';
import '../styles/navbar.css';
import arrowIcon from '../images/down-arrow.png';

const leftLinks = [
  { name: 'Home', url: '/' },
  {
    name: 'Residential',
    url: '/services/residential-surveys',
    className: 'navbar-sm',
    hasSubmenu: true,
  },
  {
    name: 'Construction',
    url: '/services/construction-services',
    className: 'navbar-sm',
    hasSubmenu: true,
  },
  { name: 'Legal', url: '/services/legal-surveys', className: 'navbar-sm', hasSubmenu: true },
  { name: 'Articles', url: '/articles', className: 'navbar-lg' },
  { name: 'About Us', url: '/about-us' },
];

const rightLinks = [
  { name: 'Quote an RPR', url: '/order-a-real-property-report', className: 'navbar-md' },
  { name: 'Quote a DP', url: '/order-a-development-permit-survey', className: 'navbar-lg' },
];

const submenus = {
  Residential: [
    {
      name: 'Residential',
      url: '/services/residential-surveys',
      className: 'submenu-desktop-hidden',
    },
    { name: 'Compliance Costs', url: '/services/residential-surveys/compliance-costs' },
    {
      name: 'Property & Fence Line Surveys',
      url: '/services/residential-surveys/property-line-surveys',
    },
    { name: 'Real Property Reports', url: '/services/residential-surveys/real-property-reports' },
  ],
  Construction: [
    {
      name: 'Construction',
      url: '/services/construction-services',
      className: 'submenu-desktop-hidden',
    },
    {
      name: 'Development Permit Surveys',
      url: '/services/construction-services/development-permits',
    },
    { name: 'Streetscapes', url: '/services/construction-services/streetscapes' },
    { name: 'Plot Plans', url: '/services/construction-services/plot-plans' },
    { name: 'House Stakeouts', url: '/services/construction-services/house-stakeouts' },
    {
      name: 'Main Floor & Footing Elevation Surveys',
      url: '/services/construction-services/main-floor-and-footing-elevation-surveys',
    },
    {
      name: 'Lot Grading Surveys & Certificates',
      url: '/services/construction-services/lot-grading-certificates',
    },
    {
      name: 'Utility Construction Surveys',
      url: '/services/construction-services/utility-construction-surveys',
    },
    {
      name: 'Large Building Construction',
      url: '/services/construction-services/large-building-construction',
    },
    { name: 'Engineering Surveys', url: '/services/construction-services/engineering-surveys' },
  ],
  Legal: [
    { name: 'Legal', url: '/services/legal-surveys', className: 'submenu-desktop-hidden' },
    { name: 'Real Property Reports', url: '/services/residential-surveys/real-property-reports' },
    { name: 'Subdivision Surveys', url: '/services/legal-surveys/subdivision-surveys' },
    { name: 'Right-of-Way Surveys', url: '/services/legal-surveys/right-of-way-surveys' },
    { name: 'Condominium Services', url: '/services/legal-surveys/condominium-services' },
    { name: 'Lease Area Surveys', url: '/services/legal-surveys/lease-area-surveys' },
  ],
};

const NavLink = ({ to, children }) => {
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const pathname = location.pathname === '/' ? '/' : location.pathname.replace(/\/$/, '');

    if (to === '/' && pathname === '/') {
      setIsActive(true);
    } else if (pathname === to || (pathname.startsWith(to) && to !== '/')) {
      setIsActive(true);
    }
  }, [location.pathname, to]);

  return (
    <span className={`navbar-item ${isActive ? 'navbar-item-active' : ''}`}>
      <Link to={to}>{children}</Link>
    </span>
  );
};

export const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const [openSubmenu, setOpenSubmenu] = useState(null);

  const toggleSubmenu = (e, submenuName) => {
    e.preventDefault();
    setOpenSubmenu(openSubmenu === submenuName ? null : submenuName);
  };

  return (
    <nav className="navbar">
      <button className="hamburger-menu" onClick={toggleMenu}>
        ☰
      </button>

      <div className={`menu-content ${isMenuOpen ? 'show' : ''}`}>
        <div>
          {leftLinks.map(({ name, url, css, hasSubmenu }) => (
            <div
              key={url}
              className={`navbar-link-container ${css || ''} ${hasSubmenu ? 'has-submenu' : ''}`}
            >
              <NavLink to={url}>
                <span
                  onClick={(e) => {
                    if (window.innerWidth <= 780 && hasSubmenu) {
                      e.preventDefault();
                      toggleSubmenu(e, name);
                    }
                  }}
                >
                  {name}{' '}
                  {hasSubmenu && (
                    <img src={arrowIcon} alt="Expand submenu" className="menu-arrow-icon" />
                  )}
                </span>
              </NavLink>

              {hasSubmenu && (
                <div className={`submenu ${openSubmenu === name ? 'show' : ''}`}>
                  {submenus[name].map((subLink) => (
                    <Link
                      key={subLink.url}
                      to={subLink.url}
                      className={`submenu-item ${subLink.css || ''}`}
                    >
                      {subLink.name}
                    </Link>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>

        <div>
          {rightLinks.map(({ name, url, css }) => (
            <span key={url} className={css}>
              <NavLink to={url}>{name}</NavLink>
            </span>
          ))}
        </div>
      </div>
    </nav>
  );
};
